let portfolio = [
    {
        id: "1",
        title: "Application web: Festival des Cannes",
        description: "Projet tutoré en équipe de 5 : Application web fullstack de gestion d'évènement avec VueJS, NodeJS, et les méthodes agiles.",
        image: "logo.png",
        start_date: "Octobre 2024",
        end_date: "Avril 2025",
        dateForSort: "2025-04",
        paragraphs: [
            {
                id: "1",
                text: "à compléter",
            },
            {
                id: "2",
                text: "à compléter",
            },
            {
                id: "1",
                text: "à compléter",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "universitaire",
    },
    {
        id: "2",
        title: "Site vitrine & Rapport économique",
        description: "Site vitrine sur Arkane Studios, et rédaction d'un rapport économique sur ladite entreprise",
        image: "logo.png",
        start_date: "Septembre 2023",
        end_date: "Octobre 2023",
        dateForSort: "2023-10",
        paragraphs: [
            {
                id: "1",
                title: "Présentation",
                text: "Pour ce projet il nous a été demandé de former un groupe de 5 étudiants et de réaliser un site vitrine ainsi qu'un rapport présentant les aspects économiques et environnementaux d'une entreprise française du numérique.",
            },
            {
                id: "2",
                text: "Nous avons donc choisi l'entreprise Arkane Studios, qui est réputée mondialement dans le milieu du jeu vidéo.",
            },
            {
                id: "3",
                title: "Les premières semaines",
                text: "Mon premier objectif en collaboration avec mon équipe, a été d'établir un cahier des charges pour le site vitrine avec notre client (joués par nos professeurs) en réalisant deux questionnaires auxquels ils ont dû répondre. Ces questionnaires contenaient des questions relatives au style et à la structure du site web, ainsi qu'aux fonctionnalités qui devraient être présentes. Ils ont été réalisé sur Google Docs pour le premier, et Google Forms pour le deuxième.",
            },
            {
                id: "4",
                text: "De plus j'ai proposé avec mon équipe des prototypes à notre client via ces mêmes questionnaires à l'aide de wireframe, zoning et style tile qui ont été fait avec l'outil MockFlow. En même temps de cela, j'ai assisté un de mes collègue avec la réalisation d'un planning selon le diagramme de PERT. Regroupant et planifiant dans le temps toutes les tâches qu'on avait à réaliser pour accomplir le projet. Pour ce faire nous avons utilisé l'outil Draw.io (ou diagrams.net). Et puis j'ai commencé avec mon équipe à entreprendre à entreprendre des recherches quant à l'entreprise Arkane Studios, en ce qui concerne sa situation économique et éventuellement écologique. Nous avons regroupé les liens et informations utiles sur Google Docs partagé avec toute l'équipe.",
            },
            {
                id: "5",
                text: "Ensuite nous avons commencé une ébauche du site web temporaire, pour avoir une base qui representera au client comment le site se présente. Et aussi afin de nous permettre de recueillir des idées et de l'inspiration quant à la réalisation du site et quant aux questionnaires.",
            },
            {
                id: "6",
                title: "Les dernières semaines",
                text: "La fin du projet était déstiné à terminer le site web, dont la répartition des tâches était basée sur les différentes pages, qui abordaient un point différent, qui composaient le site. Pour ma part je me suis occupée de la partie Organisation et Environnement de travail chez Arkane Studios. Partie sur laquelle j'étais chargée de recherche."
            },
            {
                id: "7",
                text: "Le site a été développé en utilisant de l'HTML5, du CSS ainsi que des scripts Javascripts avec la bibliothèque AOS."
            },
            {
                id: "8",
                text: "Et pour finir nous avons préparé ensemble un oral de soutenance, appuyé par un diaporama, dans lequel nous avons présenté l'entreprise, notre site et ses fonctionnalités, ainsi que notre retour sur notre expérience sur ce projet. Ma partie portait sur la mise en page et certaines fonctionnalités par rapport aux besoins du client."
            },
            {
                id: "9",
                title: "Bilan",
                text: "Pour conclure, ce premier projet en environnement scolaire (simulant un expérience professionnelle), fut enrichissante et m'a permis de développer de nombreuses compétences en terme de développement web, de manipulation d'outils de gestion de version (Github, Gitlab, Bitbucket), de recherche, et de rédaction de rapport économique. Mais il m'a surtout apporté de nombreuses méthodes de travail, notamment en équipe."
            }
        ],
        github: "https://github.com/MaikenDANGELO/LesArkaneDuStudio",
        type: "universitaire",
    },
    {
        id: "3",
        title: "Base de données & Installation de poste",
        description: "Gestion de base de données avec PyMysql. Installation d'un poste de travail Linux.",
        image: "logo.png",
        start_date: "Octobre 2023",
        end_date: "Novembre 2023",
        dateForSort: "2023-11",
        paragraphs: [
            {
                id: "1",
                title: "Présentation",
                text: "Pour ce projet nous avons formé un groupe de 3 personnes et de réaliser un site web avec Flask et PyMysql ayant pour but la gestion de la base de donnée d'une entreprise fictive qu'on a nommé Pomp'air, qui vend des pompes à chaleur.",
            },
            {
                id: "2",
                text: "En plus de cela nous avions à réaliser l'installation d'un poste pour un client fictif sur une machine virtuelle tout en rédigeant un rapport technique détaillant les étapes d'installation et les fonctionnalités ainsi que les choix techniques.",
            },
            {
                id: "3",
                title: "Le site de Pomp'air",
                text: "Notre premier objectif a été d'établir un modèle conceptuel de données et son équivalent logique (MCD et MLD) pour la gestion des données de l'entreprise avec entre autre, la gestion des clients, des modèles de pompes, des interventions et plus encore.",
            },
            {
                id: "4",
                text: "Ensuite nous avons commencé, En même temps de cela, nous avons réalisé un planning selon le diagramme de PERT. Regroupant et planifiant dans le temps toutes les tâches qu'on avait à réaliser pour accomplir le projet. Pour ce faire nous avons utilisé l'outil Draw.io (ou diagrams.net). Et puis nous avons commencé à entreprendre des recherches quant à l'entreprise Arkane Studios, en ce qui concerne sa situation économique et éventuellement écologique. Nous avons regroupé les liens et informations utiles sur Google Docs partagé avec toute l'équipe.",
            },
            {
                id: "5",
                text: "Ensuite nous avons commencé une ébauche du site web temporaire, pour avoir une base qui representera au client comment le site se présente. Et aussi afin de nous permettre de recueillir des idées et de l'inspiration quant à la réalisation du site et quant aux questionnaires.",
            },
            {
                id: "6",
                title: "À compléter...",
                text: "à compléter",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "universitaire",
    },
    {
        id: "4",
        title: "Angelo_koe",
        description: "Projet personnel",
        image: "logo.png",
        start_date: "Octobre 2024",
        end_date: "TBD",
        paragraphs: [
            {
                id: "1",
                text: "à compléter",
            },
            {
                id: "2",
                text: "à compléter",
            },
            {
                id: "3",
                text: "à compléter",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "personnel",
    },
    {
        id: "5",
        title: "ConsoleRPG",
        description: "Projet personnel",
        image: "logo.png",
        start_date: "Octobre 2023",
        end_date: "TBD",
        dateForSort: "2023-10",
        paragraphs: [
            {
                id: "1",
                text: "à compléter",
            },
            {
                id: "2",
                text: "à compléter",
            },
            {
                id: "3",
                text: "à compléter",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "personnel",
    },
    {
        id: "6",
        title: "DosSend & DosRead",
        description: "Application Java convertissant et encryptant du texte pour le transmettre sous forme de signal sonore et pouvant le decrypter.",
        image: "logo.png",
        start_date: "Décembre 2023",
        end_date: "Février 2024",
        dateForSort: "2024-02",
        paragraphs: [
            {
                id: "1",
                title: "Présentation",
                text: "à compléter",
            },
            {
                id: "2",
                text: "",
            },
            {
                id: "3",
                text: "",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "universitaire",
    },
    {
        id: "7",
        title: "Site de e-commerce & Gestion de projet",
        description: "",
        image: "logo.png",
        start_date: "Février 2024",
        end_date: "Avril 2024",
        dateForSort: "2024-04",
        paragraphs: [
            {
                id: "1",
                title: "Présentation",
                text: "",
            },
            {
                id: "2",
                text: "",
            },
            {
                id: "3",
                title: "",
                text: "",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "universitaire",
    },
    {
        id: "8",
        title: "Analyse de données : Cybersécurité",
        description: "Projet d'analyse d'un jeu de données sur des cyberattaques simulées sur des objets connectés. Utilisation de Python et de Machine Learning.",
        image: "logo.png",
        start_date: "Octobtre 2024",
        end_date: "Novembre 2024",
        dateForSort: "2024-11",
        paragraphs: [
            {
                id: "1",
                title: "Présentation",
                text: "Ce projet a pour objectif l'analyse de données tirées d'un dataset CIC IoT DataSet 2022, trouvé sur https://www.unb.ca/cic/datasetsiotdataset-2022.html, dans une optique de cybersécurité. Je forme ma propre équipe composée de moi-même: - Maïken D'Angelo ",
            },
            {
                id: "2",
                text: "L'IoT (Internet of Things) ou Internet des Objets est tout ce qui concerne les objets connectés et la domotique. C'est un concept de plus en plus répandu dans notre société, ce qui rend notre quotidien d'autant plus vulnérable aux attaques de piratages ou d'intrusion. Il est donc important de savoir analyser ces attaques pour mieux s'en protéger. ",
            },
            {
                id: "3",
                title: "Jeu de données",
                text: "Le jeu de données fourni comprend 28 appareils différents, lesquels ont été testés 3 fois dans 10 modes différents. Ce qui donne 840 fichiers CSV différents, il faut donc regrouper les données de manières adéquate pour pouvoir les analyser facilement. ",
            },
            {
                id: "4",
                title: "Comment charger les données ?",
                text: "Avec autant de fichiers CSV, il est impensable de tous les charger et analyser un à un. C'est pourquoi j'ai écrit un script permettant de parcourir tous les dossiers et fichiers, et de les charger dans un seul dataframe. De plus on ajoute à chaque fichier une nouvelle colonne permettant de savoir de quel apparail les données proviennent, une autre pour savoir sous quelles conditions étaient cet appareil, et une dernière correspondant au type d'appareil. ",
            },
            {
                id: "5",
                title: "Quelles données ? Quels traitements ?",
                text: "Tout d'abord il faut nettoyer les données, par exemple en indexant correctement celles-ci, et en enlevant les valeurs nulles ou dupliquées. Après cela j'ai effectué quelques tests pour prendre en main le jeu de données. ",
            },
            {
                id: "6",
                text: "Puis j'ai commencé à décrire ces données, et trouver les colonnes interessantes / suspectes. Pour cela je me suis donné un aperçu en construisant une correlation heatmap, ce qui ne m'a pas beaucoup avancé. Ensuite j'ai utilisé un algorithme pour trouver les “most important features”, avec lesquelles j'ai construit un pairplot d'après le type des appareils (home_automation, camera, audio), mais les données n'étaient pas très explicites. ",
            },
            {
                id: "7",
                text: "C'est alors que j'ai commencé à entraîner des modèles de Machine Learning (ML), en commençant par un RandomForestClassifier, sur ces “most important features”, afin de trouver le pourcentage de précision du modèle. En echaînant avec d'autres modèles comme GradientBoostingClassifier, AdaBoostClassifier, BaggingClassifier et ExtraTreesClassifier, j'ai pu comparer leur précision de différentes manières : - en récupérant directement les valeurs et en faire un nouveau dataset afin de travailler dessus directement - en utilisant un cross_val_score pour être sûre des valeurs et de même en faire un dataset. ",
            },
            {
                id: "8",
                text: "On peut observer que les modèles donnent tous la même précision moyenne (au millième près), sauf AdaBoostClassifier, aux alentours de 91%.",
            },
            {
                id: "9",
                text: "Après cela, j'ai ajouté au dataframe les recall_score et f1_score de chaque models pour chaque most_important_features, et ai réalisé un graphique pour les comparer. ",
            },
            {
                id: "10",
                text: "Pour vérifier mes résultats et mon workflow, j'ai décidé de lire l'article des scientifique ayant publié ce dataset et analyser ce qu'ils ont fait et comparer mon travail au leur, pour mieux comprendre et m'améliorer. ",
            },
            {
                id: "11",
                title: "Conclusion",
                text: "À compléter...",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "universitaire",
    },
    {
        id: "8",
        title: "Archinsky",
        description: "TBD",
        image: "logo.png",
        start_date: "TBD",
        end_date: "TBD",
        dateForSort: "2025-06",
        paragraphs: [
            {
                id: "1",
                title: "Présentation",
                text: "",
            },
            {
                id: "2",
                text: "",
            },
            {
                id: "3",
                title: "",
                text: "",
            },
        ],
        github: "https://github.com/MaikenDANGELO/LeFestivalDesCannes/",
        type: "personnel",
    },
]

module.exports = {
    portfolio,
}