<template>
    <div class="contact">
        <h1 class="title">Me contacter</h1>
        <div class="mail-contact">
            <h2 class="title2">Me contacter par e-mail</h2>
            <p>Adresse e-mail personnelle: <a href="mailto:maiken.dangelo@gmail.com">maiken.dangelo@gmail.com</a></p>
            <p>Adresse e-mail universitaire: <a href="mailto:maiken.d_angelo@edu.univ-fcomte.fr">maiken.d_angelo@edu.univ-fcomte.fr</a></p>
            <h2 class="title2">Me contacter par téléphone</h2>
            <p>Au numéro : +33 7 83 85 78 60</p>
        </div>
        <div>
            <h2 class="title2">Mes réseaux</h2>
            <div>

            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
.title{
    font-size: 36px;
    text-align: center;
}
.title2{
    font-size: 24px;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
.contact{
    text-align: justify;
}
</style>